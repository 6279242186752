import { useEffect, useState, createContext, useContext } from 'react';
import {
  useUser as useSupaUser,
  User
} from '@supabase/supabase-auth-helpers/react';
import { UserDetails } from 'types';
import { Subscription } from 'types';
import { SupabaseClient } from '@supabase/supabase-auth-helpers/nextjs';

type UserContextType = {
  accessToken: string | null;
  user: User | null;
  userDetails: UserDetails | null;
  isLoading: boolean;
  subscription: Subscription | null;
  payments: any | null;
};

export const UserContext = createContext<UserContextType | undefined>(
  undefined
);

export interface Props {
  supabaseClient: SupabaseClient;
  [propName: string]: any;
}

export const MyUserContextProvider = (props: Props) => {
  const { supabaseClient: supabase } = props;
  const { user, accessToken, isLoading: isLoadingUser } = useSupaUser();
  const [isLoadingData, setIsloadingData] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [payments, setPayments] = useState(null);
  const [myCompletedCourses, setMyCompletedCourses] = useState([]);

  // console.log({userDetails})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPayments = () => supabase
      .from('payments')
      .select('*, prices(*, products(*))')
      .eq('user_id', user?.id)
      .order('created', { ascending: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getCompletedCourses = () => supabase
      .from('completed_courses')
      .select('*')
      .eq('user_id', user?.id)
      .order('updated_at', { ascending: false });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserDetails = () =>
    // FIXME:
    supabase.from<UserDetails>('users').select('*').eq('id', user?.id).single();
    // supabase.from<UserDetails>('users').select('*').single();
    // eslint-disable-next-line react-hooks/exhaustive-deps


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getSubscription = () =>
    supabase
      .from<Subscription>('subscriptions')
      .select('*, prices(*, products(*))')
      .in('status', ['trialing', 'active'])
      .eq('user_id', user?.id)
      // FIXME: or .maybeSingle()
      .maybeSingle();

  useEffect(() => {
    if (user && !isLoadingData && !userDetails && !subscription) {
      setIsloadingData(true);
      Promise.allSettled([getUserDetails(), getSubscription(), getPayments(), getCompletedCourses()]).then(
        (results) => {
          const userDetailsPromise = results[0];
          const subscriptionPromise = results[1];
          const paymentsPromise = results[2];
          const completedCoursesPromise = results[3];

          if (userDetailsPromise.status === 'fulfilled')
            setUserDetails(userDetailsPromise.value.data);

          if (subscriptionPromise.status === 'fulfilled')
            setSubscription(subscriptionPromise.value.data);

          if(paymentsPromise.status === 'fulfilled')
            setPayments(paymentsPromise.value.data);

          if(completedCoursesPromise.status === 'fulfilled')
          setMyCompletedCourses(completedCoursesPromise.value.data);

          setIsloadingData(false);
        }
      );
    } else if (!user && !isLoadingUser && !isLoadingData) {
      setUserDetails(null);
      setSubscription(null);
      setPayments(null);
      setMyCompletedCourses(null);
    }
  }, [user, isLoadingUser, isLoadingData, userDetails, subscription, getUserDetails, getSubscription, getPayments, getCompletedCourses]);

  const value = {
    accessToken,
    user,
    userDetails,
    isLoading: isLoadingUser || isLoadingData,
    subscription,
    payments,
    myCompletedCourses
  };

  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    console.error(`useUser must be used within a MyUserContextProvider.`);
  }
  return context;
};
